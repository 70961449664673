import classNames from 'classnames';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { IoAlarmOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { selectAccessHoursEvents } from '../../../../../../../../../../../selectors/accessHoursSelector';
import * as ICAL from 'ical';
import './index.scss';

interface Props {
	deviceSerial: string;
}

export const AccessHoursWithPopover = ({ deviceSerial }: Props) => {
	const accessHoursEvents = useSelector(selectAccessHoursEvents);

	const [showAccessHours, setShowAccessHours] = useState(false);
	const [showPopover, setShowPopover] = useState(false);

	const AccessHoursNotBlocking = useRef(true);
	const [formattedStartTime, setFormattedStartTime] = useState('');
	const [formattedEndTime, setFormattedEndTime] = useState('');
	const [days, setDays] = useState<string[]>([]);

	useEffect(() => {
		// This if() will implicitly hide this component from guest users, as they do not have access to the "accessHoursEvents.accessHoursEvents"
		// As this is the desired behavior, we do not perform any checks here
		if (Object.keys(accessHoursEvents.accessHoursEvents).includes(deviceSerial)) {
			const event = ICAL.parseICS(accessHoursEvents.accessHoursEvents[deviceSerial]);

			const UUID: string = Object.keys(event)[0];

			//@ts-ignore
			const rruleString: string = event[UUID].rrule;

			const match = rruleString.match(/BYDAY=([^;]+)/); // Extract days from the rrule string
			if (!match) {
				AccessHoursNotBlocking.current = false;
			} else {
				// Verify if current day is one where access is available
				const daysArray = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
				const currentDayIndex = new Date().getDay();
				const currentDay = daysArray[currentDayIndex - 1];

				const daysList = match[1].split(',');
				setDays(daysList);

				if (!daysList.includes(currentDay)) {
					AccessHoursNotBlocking.current = false;
				}

				const now = new Date();
				const startTime = event[UUID]['start']!;
				const endTime = event[UUID]['end']!;

				const nowDate = new Date(now.setSeconds(0, 0));
				const startDate = new Date(startTime.setSeconds(0, 0));
				const endDate = new Date(endTime.setSeconds(0, 0));

				if (
					!(
						endDate.getTime() > nowDate.getTime() &&
						startDate.getTime() < nowDate.getTime()
					)
				) {
					AccessHoursNotBlocking.current = false;
				}

				// Format times as strings for display
				const formatTime = (date: Date) =>
					date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

				setFormattedStartTime(formatTime(startDate));
				setFormattedEndTime(formatTime(endDate));
			}
			setShowAccessHours(true);
		} else {
			setShowAccessHours(false);
		}
	}, [accessHoursEvents, deviceSerial]);

	return showAccessHours ? (
		<div
			className={classNames('reservationOverview')}
			onMouseOver={() => setShowPopover(true)}
			onMouseOut={() => setShowPopover(false)}
		>
			<div className="reservedWrapper">
				<IoAlarmOutline
					className={classNames('accessHoursIcon', {
						greenAccessHoursIcon: AccessHoursNotBlocking.current,
						redAccessHoursIcon: !AccessHoursNotBlocking.current,
					})}
				/>
			</div>
			<div
				className={classNames('popoverWrapper', {
					popoverHide: !showPopover,
				})}
			>
				<span className="sectionHeader">Access Hours</span>
				<div className="bodyM">Robot available on:</div>
				<div className="bodyM">{days.join(', ')}</div>
				<div className="bodyM">During:</div>
				<div className="bodyM">
					{formattedStartTime} - {formattedEndTime}
				</div>

				{AccessHoursNotBlocking.current ? (
					<p className="caption" style={{ color: '#AAAAAA', marginTop: 6 }}>
						The robot is currently available to be called
					</p>
				) : (
					<p className="caption" style={{ color: '#AAAAAA', marginTop: 6 }}>
						The robot is currently NOT available to be called
					</p>
				)}
			</div>
		</div>
	) : null;
};
